<template>
  <div>
    <leaflet-panel
        :body="body"
        :image="image"
        :show-buy-btn="showBuyBtn"
        :show-detail-btn="showDetailBtn"
        :title="title"
    />
    <Paragraph :imgurl="require(`@/assets/image/contentsvg/map.svg`)"
               :title="`产品功能`" :en_title="`Product Features`"></Paragraph>
    <Contentlist :datalist="datalist1"></Contentlist>
    <Paragraph :imgurl="require(`@/assets/image/contentsvg/king.svg`)"
               :title="`行业背景和业务痛点`"
               :en_title="`Industry Background & Business Pain Points`"></Paragraph>

    <Titlestrlist :datalist="datalist"></Titlestrlist>
    <Paragraph :imgurl="require(`@/assets/image/contentsvg/note.svg`)"
               :title="`应用场景`"
               :en_title="`Application Scenarios`"></Paragraph>

    <div  style="margin-left: 10%;margin-right: 10%;
                display: flex;flex-direction: row;justify-content: center;">
      <div v-for="item in datalistx" :key="item.index"
          style="
             align-items: center;
             width:30rem ;">
        <div style="flex-direction: row;
           display: flex;flex-direction: column;justify-content: center;">
        <div style="height:4rem;
            ">
        <img :src="item.imageurl"
             style="width: 100%;height: 100%;align-items: center">
        </div>
        </div>
        <div style="font-size: 1.8rem;font-weight: bold;margin-top: 2rem;
        margin-bottom: 1rem;letter-spacing: 0.2rem;
                    color: #2285ff;">
          {{item.title}}
        </div>
        <div style="color: #272727; font-size: 1.4rem;letter-spacing: 0.1rem;
             ">
          <p v-for="ditem in item.desc" :key="ditem.index"
             style="padding: 0.2rem;margin: 0" v-html="ditem">
          </p>
        </div>
        <div style="margin-top: 3rem;margin-bottom: 8rem;
                    display: flex;flex-direction: column;
                    font-size: 1.2rem;
                   align-items: center;color: #686868;">
          <p v-for="citem in item.list" :key="citem.index"
            style="padding: 0.2rem;margin: 0" v-html="citem">
          </p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import LeafletPanel from '@/components/common/LeafletPanel';
import Paragraph from '@/components/chain/basic/common/paragraph';
import Contentlist from '@/components/chain/basic/common/contentlist';
import Titlestrlist from '@/components/chain/basic/common/titlestrlist';

export default {
  name: 'BaaSLeaflet',
  components: {
    LeafletPanel,
    Paragraph,
    Contentlist,
    Titlestrlist,
  },
  data() {
    return {
      title: '浏览器服务',
      body:
          '域乎链服区块链浏览器可以用来查看通过域乎链服平台部署的区块链系统的内部信息、比如区块数、区块详细信息、交易数目、交易详细信息、链上统计数据等。',
      image: require('@/assets/image/chain/basic/browser/panel.webp'),
      showDetailBtn: true,
      showBuyBtn: true,
      images: [
        require('@/assets/image/chain/basic/browser/5_01.jpg'),
        require('@/assets/image/chain/basic/browser/5_02.jpg'),
        require('@/assets/image/chain/basic/browser/5_03.jpg'),
      ],
      datalistx: [
        {
          imageurl: require('@/assets/image/contentsvg/fly.svg'),
          title: '域乎链服浏览器',
          desc: ['通用浏览器', '&nbsp;'],
          list: [
            '可以一键部署',
            '适配大多数区块链底层',
            ' &nbsp;',
            ' &nbsp;',
          ],
        }, {
          imageurl: require('@/assets/image/contentsvg/book.svg'),
          title: '智房链浏览器',
          desc: ['基于Fisco-Bcos搭建的', '房产联盟链浏览器'],
          list: [
            '除了展示链的原始信息',
            '还可展示上链的行业数据',
            '例如房产交易中参与的机构、不动产信息、',
            '委托单、成交量和成交额等信息',
          ],
        }, {
          imageurl: require('@/assets/image/contentsvg/car.svg'),
          title: '农业链浏览器',
          desc: ['建设基于农业产业链的', '行业联盟链'],
          list: [
            '除了链上原始信息',
            '浏览器同时根据行业联盟需要展示',
            '产业链上农资、种植、流通、交易等',
            '全流程溯源信息',
          ],
        },
      ],
      datalist: [
        { title: '>  全面性', content: '具备全面的区块链浏览器功能' },
        { title: '>  灵活性', content: '可根据应用场景需求，灵活选择浏览器功能' },
        { title: '>  适配性', content: '通用浏览器适配大部分区块链底层' },
        { title: '>  个性化', content: '可根据应用场景，定制化设计区块链浏览器' },
        { title: '>  便利性', content: '通用区块链浏览器可以一键部署搭建' },
        { title: '>  可视化', content: '链原始数据及衍生数据皆可视化' },
        { title: '>  交互性', content: '出来查询链上数据，还可管理自己的区块链账号' },
        { title: '>  扩展性', content: '可根据应用场景需要，对浏览器功能进行扩展' },
        { title: '>  低门槛', content: '非开发者用户也可简单操作使用' },
      ],
      datalist1: [{
        title: '1）链数据展示与查询',
        list: [
          '原始数据：链信息、区块信息、交易信息、合约信息、账户信息等',
          '衍生数据：可对链上原始数据做衍生数据展示，例如链上交易数据统计等',
          '链核心数据：选用不同的链底层展示不同的性能、机制等特性，例如：TPS、共识机制、存储、繁荣指标、资产价值等',
        ],
      }, {
        title: '2）数据可视化',
        list: [
          '数据可视化能够帮助用户更加直观地体会到区块链关键指标',
        ],
      }, {
        title: '3）账号管理',
        list: [
          '用户可创建个人账号，监管链上地址交易，管理自己的地址、交易记录以及资产',
        ],
      }, {
        title: '4）关联钱包',
        list: [
          '用户可对所持资产进行存储、转账和交易等行为',
        ],
      }, {
        title: '5）DApp数据分析',
        list: [
          '对链上发布的DApp进行分类展示、数据统计、分析与排名',
        ],
      },
      ],
    };
  },
};
</script>

<style scoped></style>
